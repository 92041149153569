import logo from "../assets/logo.svg";
import background from "../assets/bgAbout.png";
import star from "../assets/star.svg";
import darr from "../assets/darr.svg";
import { useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function About() {
  useLayoutEffect(() => {
    let ct1 = gsap.context(() => {
      const t1 = gsap.timeline();
      t1.from("#paragraph", {
        yPercent: 50,
        stagger: 0.1,
        opacity: 0,
        scrollTrigger: {
          trigger: "#screenAnimAbout",
          start: "top center",
          end: "center center",
          scrub: true,
        },
      });
      t1.from("#starAnim", {
        xPercent: 100,
        yPercent: 100,
        rotation: 15,
        stagger: 0.45,
        opacity: 0,
        width: 0,
        duration: 2.5,
        ease: "power2.out",
        scrollTrigger: {
          trigger: "#screenAnimAbout",
          start: "top center",
          end: "center center",
          scrub: true,
        },
      });
    });
    return () => ct1.revert();
  }, []);

  return (
    <div
      id="screenAnimAbout"
      className="snap-start relative h-dvh w-dvh object-cover bg-fixed bg-center bg-cover overflow-hidden"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      {/* Container */}
      <div className="flex justify-between w-full h-full">
        {/* ABOUT US */}
        <div className="flex flex-col justify-between relative w-2/5 h-5/6 rounded-br-[5rem] bg-black/40 shadow-zinc-800 backdrop-blur-md overflow-hidden">
          {/* logo */}
          <img id="starAnim" className="p-6 w-60" src={logo} alt="Logo" />
          <div className="flex flex-col justify-between h-full text-sm 2xl:text-lg text-white p-6">
            <h2 className="text-3xl xl:text-5xl font-black pb-3">About Us</h2>
            <p id="paragraph" className="px-2">
              The total area of the plant is 15.2 hectares. After the reconstruction of the plant, production capacity
              increased to 250,000 tons per year.
            </p>
            <div className="h-[0.1rem] w-dvh bg-white my-3 mx-2 mr-12 rounded-full"></div>
            <p id="paragraph" className="px-2">
              The steelmaking shop of the plant is equipped with a 25-ton capable electric arc furnace for melting metal
              and a ladle furnace unit of the same capacity for out-of-furnace processing of liquid steel.
            </p>
            <div className="h-[0.1rem] w-dvh bg-white my-3 mx-2 mr-12 rounded-full"></div>
            <p id="paragraph" className="px-2">
              The company produces construction reinforcement according to the GOST-52544-2006 standard with a diagonal
              up to Ø10 - 25 mm. Finished products are supplied to both local markets and global markets.
            </p>
            <div className="h-[0.1rem] w-dvh bg-white my-3 mx-2 mr-12 rounded-full"></div>
          </div>

          <img className="mx-auto w-9 xl:w-12 text-red-600 m-6 invisible" src={darr} alt="Down Arrow" />
        </div>

        {/* Our Capabilities */}

        <div className="flex flex-col justify-between self-end relative w-2/5 h-5/6 rounded-tl-[5rem] bg-black/40 shadow-zinc-800 backdrop-blur-md overflow-hidden">
          {/* logo */}
          <div className="mt-9 flex flex-col justify-between h-full text-sm 2xl:text-xl text-white p-6">
            <h2 className="text-3xl xl:text-5xl font-black pb-3">Our Capabilities</h2>
            <p id="paragraph" className="px-2">
              The plant utilizes a long rolling mill with an annual production capacity of 200,000 tons. The section
              rolling mill includes a working machine, which includes ten working stands.
            </p>
            <div className="h-[0.1rem] w-dvh bg-white my-3 mx-2 mr-12 rounded-full"></div>
            <p id="paragraph" className="px-2">
              After completion of the reconstruction work, a "Termex" unit was installed at the enterprise for the
              production of high-strength reinforcement using the thermomechanical method.
            </p>
            <div className="h-[0.1rem] w-dvh bg-white my-3 mx-2 mr-12 rounded-full"></div>
            <p id="paragraph" className="px-2">
              The factory is equipped with gas equipment for cutting metal, press shears for recycling ferrous metal and
              transport mechanisms that serve them, ensuring stable operation. Scrap metal arriving at the plant
              undergoes a number of treatments: sorting, cutting with press shears, pressing for briquetting. Sending to
              the charge yard and finally fed in special tubs into electric arc furnaces for casting.
            </p>
            <div className="h-[0.1rem] w-dvh bg-white my-3 mx-2 mr-12 rounded-full"></div>
          </div>

          <img className="mx-auto w-9 xl:w-12 text-red-600 m-6 invisible" src={darr} alt="Down Arrow" />
        </div>
      </div>
      <div id="trig" className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
        <img id="starAnim" className="w-32" src={star} alt="Star" />
        <img id="starAnim" className="w-32" src={star} alt="Star" />
        <img id="starAnim" className="w-32" src={star} alt="Star" />
      </div>
    </div>
  );
}
export default About;
