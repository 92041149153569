import logo from "../assets/logo.svg";
import background from "../assets/bgMain.png";
import { useLayoutEffect } from "react";
import gsap from "gsap";
import darr from "../assets/darr.svg";
function Main() {
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const t1 = gsap.timeline();
      t1.from("#intro-slider", {
        xPercent: "-100",
        duration: 0.7,
        delay: 0.3,
      });
      t1.from("#logo", {
        opacity: "0",
        yPercent: "100",
        duration: 0.5,
        delay: 0.2,
      });
      t1.from("#title", {
        opacity: "0",
        yPercent: "100",
        duration: 0.5,
        delay: 0.1,
      });
      t1.from("#name", {
        opacity: "0",
        yPercent: "100",
        duration: 0.4,
        delay: 0.1,
      });
      t1.from("#button", {
        opacity: "0",
        yPercent: "100",
        duration: 0.35,
        delay: 0.1,
      });
      t1.from("#arrow", {
        opacity: "0",
        yPercent: "100",
        duration: 0.4,
        delay: 0.1,
      });
    });
    return () => ctx.revert();
  }, []);
  return (
    <div
      className="snap-start relative h-dvh w-dvh object-cover 
      bg-fixed bg-center bg-cover overflow-hidden"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      {/* Content */}
      <div
        id="intro-slider"
        className="
        flex flex-col justify-between relative 
        w-4/5 sm:w-3/5 xl:w-2/5 h-dvh rounded-tr-[5rem]
         bg-gray-500/20 shadow-zinc-800 backdrop-blur-md 
         overflow-hidden mt-3 top-0 pt-2 pb-9"
      >
        {/* logo */}
        <img id="logo" className="p-6 w-60 xl:w-80" src={logo} alt="Logo" />
        {/* Text */}
        <div className="text-white p-6">
          <h2 id="title" className="text-8xl font-black pb-3">
            We Sell
          </h2>
          <p id="name" className="w-full px-2">
            We are operating in the metal industry. We are engaged in the production of construction reinforcement.
          </p>
          <button
            id="button"
            className="text-lg text-left text-white font-bold bg-red shadow-md shadow-red rounded-full p-3 px-9 m-6 mx-auto"
          >
            Read more
          </button>
        </div>

        <img id="arrow" className="mx-auto w-9 xl:w-12 text-red m-6 mb-12 animate-bounce" src={darr} alt="Down Arrow" />
      </div>
    </div>
  );
}
export default Main;
