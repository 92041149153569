import logo from "../assets/logo.svg";
import background from "../assets/bg.png";
import star from "../assets/star.svg";
import mail from "../assets/mail.svg";
import darr from "../assets/darr.svg";
import location from "../assets/location.svg";
import rectangle from "../assets/rectangleContact.svg";
import sawter from "../assets/sawter.png";
import gsap from "gsap";
import { useLayoutEffect } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Contact() {
  useLayoutEffect(() => {
    let ct3 = gsap.context(() => {
      const t3 = gsap.timeline();
      t3.from("#boxAnim", {
        yPercent: -100,
        ease: "power1.in",
        scrollTrigger: {
          trigger: "#screenAnim",
          start: "top center",
          end: "center center",
          scrub: true,
        },
      });
    });
    return () => ct3.revert();
  }, []);
  const handleMailButtonClick = () => {
    const email = "info@aktausteel.kz";
    const subject = "Additional Information, ";
    const body = "Hello, ";
    console.log("Mailbox Triggered");
    // Construct the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client
    window.location.href = mailtoLink;
    window.open(mailtoLink, "_blank");
  };

  return (
    <div
      id="screenAnim"
      className="h-dvh w-dvh relative object-cover bg-fixed bg-cover overflow-x-hidden snap-start"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      {/* Card box */}
      <div className="flex flex-col items-center justify-center h-full text-white">
        <div
          id="boxAnim"
          className="relative w-[65%] h-[65%] rounded-3xl bg-white/30 shadow-zinc-800 backdrop-blur-md overflow-hidden"
        >
          <div className="flex justify-start">
            <div className="absolute top-0 right-0 p-3">
              <img className="z-20" src={star} alt="star" />
            </div>
            {/* Image */}
            <img className="w-0 lg:w-1/2 2xl:w-1/3 h-full shrink-0" src={sawter} alt="" />
            {/* CARD CONTENT */}
            <div className="h-full w-full relative flex flex-col justify-between">
              {/* Card Title */}
              <div className="flex h-full w-full p-3">
                <img id="logoo" className="w-48 p-1 border-r-2" src={logo} alt="" />
                <h1 className="text-2xl font-bold px-3 p-3">Contact</h1>
              </div>
              {/* Contact Information */}
              {/* MAIL */}
              <div className="p-3 text-lg xl:text-xl 2xl:text-4xl font-light flex flex-col justify-between gap-9 2xl:gap-16 2xl:pt-12">
                <div className="flex">
                  <img className="w-3 md:w-6 xl:w-9 p-1 mr-3" src={mail} alt="" />
                  <p>
                    <span className="text-red font-bold">info</span>@aktausteel.kz
                  </p>
                </div>
                {/* ADRESS */}
                <div className="flex flex-start items-start">
                  <img className="w-3 md:w-6 xl:w-9 p-1 mr-3" src={location} alt="" />
                  <p>
                    <span className="text-red font-bold">Proomyshlennaya Zona 6,</span> <br /> Zdanie 85 <br />
                    Aktau Kazakhstan
                  </p>
                </div>
              </div>
              {/* QR Code */}
            </div>
          </div>
          {/* ABSOLUTES */}
          <img
            className="absolute bottom-0 right-0 px-9 mx-6 -z-10"
            src={rectangle}
            alt="Rectangle Contact Background"
          />
          <button
            className="absolute bottom-0 right-0 p-3 px-9 m-3 bg-blue rounded-full text-lg font-semibold z-20"
            onClick={handleMailButtonClick}
          >
            Send Email
          </button>
        </div>
      </div>
      {/* Absolutes */}
      <img
        id="arrow"
        className="absolute left-1/2 -translate-x-1/2 bottom-0 mx-auto w-9 xl:w-12 text-red m-6 mb-12  shadow-red animate-bounce"
        src={darr}
        alt="Down Arrow"
      />
    </div>
  );
}
export default Contact;
