import "../gallery.css";
import logo from "../assets/logo.svg";
import background from "../assets/bg.png";

function Gallery() {
  return (
    <div
      id="screenAnim"
      className="h-dvh w-dvh relative object-cover bg-fixed bg-cover overflow-x-hidden snap-start z-20 overflow-clip"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <header className="h-dvh absolute left-1/2 transform -translate-x-1/2 w-[95%]">
        {/* logo */}
        <div className="flex flex-row font-medium text-2xl text-end p-3 px-9">
          <img id="logo" className=" w-32 xl:w-36" src={logo} alt="Logo" />
          <h1 className="text-white pt-9">/ Gallery</h1>
        </div>

        <div class="container" className="p-1 md:m-9 md:mx-20 border border-blax z-20">
          <div class="gallery-wrap">
            <div class="item item-0 grayscale hover:grayscale-0 transition-all duration-300"></div>
            <div class="item item-1 grayscale hover:grayscale-0 transition-all duration-300"></div>
            <div class="item item-2 grayscale hover:grayscale-0 transition-all duration-300"></div>
            <div class="item item-3 grayscale hover:grayscale-0 transition-all duration-300"></div>
            <div class="item item-4 grayscale hover:grayscale-0 transition-all duration-300"></div>
            <div class="item item-5 grayscale hover:grayscale-0 transition-all duration-300"></div>
            <div class="item item-6 grayscale hover:grayscale-0 transition-all duration-300"></div>
            <div class="item item-7 grayscale hover:grayscale-0 transition-all duration-300"></div>
          </div>
        </div>
        <h3 className="absolute left-0 bottom-0 p-1">® 2024 / Aktau Steel</h3>
      </header>
      <div className="absolute backdrop-blur-lg -left-32 -bottom-52 rounded-full size-96 -z-20"></div>
      <div className="absolute backdrop-blur-sm -left-32 -bottom-52 rounded-full size-[48rem] -z-10"></div>
      <div className="absolute bg-red -left-12 -bottom-32 rounded-full size-64 -z-30 animate-pulse"></div>
    </div>
  );
}
export default Gallery;
