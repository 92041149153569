import background from "../assets/bg.png";
import logo from "../assets/logo.svg";
import { useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Loading() {
  useLayoutEffect(() => {
    let ct1 = gsap.context(() => {
      const t1 = gsap.timeline();
      t1.from("#loadingCircle", {
        stagger: 0.1,
        duration: 1.5,
        opacity: 0,
      });
      t1.to("#loadingCircle", {
        width: 3000,
        stagger: 0.1,
      });
      t1.to("#loadingPage", {
        yPercent: -100,
      });
      // t1.from("#starAnim", {
      //   xPercent: 100,
      //   yPercent: 100,
      //   rotation: 15,
      //   stagger: 0.45,
      //   opacity: 0,
      //   width: 0,
      //   duration: 2.5,
      //   ease: "power2.out",
      // });
    });
    return () => ct1.revert();
  }, []);
  return (
    <div
      id="loadingPage"
      className="h-dvh w-dvh relative z-20 object-cover bg-fixed bg-cover overflow-x-hidden snap-start"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="flex justify-center items-center h-dvh">
        {/* Box */}
        <img id="loadingLogo" className="size-96 z-30" src={logo} alt="Logo" />
      </div>
      <div
        id="loadingCircle"
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full box size-[30rem] z-0 bg-black/20 shadow-zinc-300 backdrop-blur-md overflow-hidden"
      ></div>
    </div>
  );
}
export default Loading;
