import Main from "./components/main.jsx";
import About from "./components/about.jsx";
import Contact from "./components/contact.jsx";
import Gallery from "./components/gallery.jsx";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import React, { useState, useEffect } from "react";
import Loading from "../src/components/loading.jsx";
function App() {
  const lenis = useLenis(({ scroll }) => {});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <ReactLenis root>
      {/* <ReactLenis root options={{ infinite: true }}> */}
      <div className="font-montserrat">
        {loading ? (
          <Loading />
        ) : (
          <section className="">
            <Main />
            <About />
            <Contact />
            <Gallery />
          </section>
        )}
      </div>
    </ReactLenis>
  );
}
export default App;
